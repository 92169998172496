import React from "react";
import "./TasksTabBar.scss";
import Svg from "components/layout/Svg/Svg";
import { tasksTabBarBgIcon } from "assets/svg";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useTooltip } from "hooks/usetooltip";

const { regularTasksText, advancedTasksText, specialBuildingsText } =
  TRANSLATIONS.tasks;

const inDevelopmentText = TRANSLATIONS.inDevelopmentText;

const tabs = [regularTasksText, advancedTasksText, specialBuildingsText];

const TasksTabBar = ({ activeTab, setActiveTab }) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const { show, openTooltip } = useTooltip();

  return (
    <>
      <div className="tasks-tabbar f-center-jcsb">
        <div className="tasks-tabbar__wrapper f-center">
          {tabs.map((item, index) => (
            <div
              key={index}
              className={`tasks-tabbar__item f-center-center ${
                activeTab === index && "active"
              }`}
              onClick={() => {
                if (index) {
                  openTooltip();
                } else {
                  setActiveTab(index);
                }
              }}
            >
              {item[siteLanguage]}
            </div>
          ))}
        </div>
        <div className="tasks-tabbar__bg">
          <Svg id={tasksTabBarBgIcon} />
        </div>
      </div>
      <Tooltip show={show} text={inDevelopmentText[siteLanguage]} />
    </>
  );
};

export default TasksTabBar;
