import React, { useState } from "react";
import "./AppContent.scss";
import { useTelegram } from "../../hooks/useTelegram";
import { useEffect } from "react";
import { getData } from "store/actions/profile";
import { useDispatch, useSelector } from "react-redux";
import {
  increaseDailyTimer,
  increasePermanentTimer,
  increaseSpeedTimer,
} from "store/slices/profileSlice";
import AppRouter from "router/AppRouter";
import Header from "components/Header/Header";
import MobileNav from "components/MobileNav/MobileNav";
import AppLoader from "components/AppLoader/AppLoader";
import ErrorDesk from "components/ErrorDesk/ErrorDesk";
import { useHarvestPerSec } from "hooks/useHarvestPerSec";
import { useTimer } from "hooks/useTimer";
import { useImageLoader } from "hooks/useImageLoader";
import { LANGUAGES } from "constants/languages";
import { setLanguage } from "store/slices/uiSlice";
import { TRANSLATIONS } from "assets/translations";

const { gettingDataText, addBlockedText, longLoadingText } =
  TRANSLATIONS.errorDesk;

const errorTexts = {
  adBlocked: addBlockedText,
  gettingData: gettingDataText,
  longLoading: longLoadingText,
};

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const tg = useTelegram();
  const dispatch = useDispatch();
  const [errorDeskShowing, setErrorDeskShowing] = useState(false);
  const [error, setError] = useState(""); // keys of errorTexts
  const imageLoading = useImageLoader();
  const timeSpeedBonus = useSelector(
    (state) => state.profile.timer_speed_bonus
  );
  const endOfDay = useSelector((state) => state.profile.dailylogin.end_of_day);
  const datePermanentBonus = useSelector(
    (state) => state.profile.date_permanent_bonus
  );

  useHarvestPerSec();
  useTimer(timeSpeedBonus, increaseSpeedTimer);
  useTimer(datePermanentBonus, increasePermanentTimer);
  useTimer(endOfDay, increaseDailyTimer);

  const checkIsBlocked = () => {
    // check if adsBlocked

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src =
      "//www.highperformanceformat.com/b8cf70b3ea2d5849d9d0a40c0c9341c7/invoke.js";
    script.onerror = (err) => {
      setError("adBlocked");
      setErrorDeskShowing(true);
    };
    script.onload = () => {
      setErrorDeskShowing(false);
      if (document.querySelector("#script-wrapper"))
        document.querySelector("#script-wrapper").innerHTML = "";
    };

    if (document.querySelector("#script-wrapper"))
      document.querySelector("#script-wrapper").appendChild(script);
  };

  useEffect(() => {
    tg.ready();

    const getLanguage = (language_code) => {
      const lang = localStorage.getItem("language");

      if (lang && lang in LANGUAGES) return lang;
      else if (language_code) {
        if (language_code.includes("ru")) {
          return LANGUAGES.rus;
        }
      }

      return LANGUAGES.eng;
    };

    if (tg) {
      if (process.env.NODE_ENV === "development") {
        (async () => {
          try {
            await dispatch(
              getData({
                tgId: 1635396805,
                avatar: null,
                siteLanguage: getLanguage(),
              })
            );
          } catch (error) {
            setError(
              error.message === "Request timed out"
                ? "longLoading"
                : "gettingData"
            );
            setErrorDeskShowing(true);
          } finally {
            setLoading(false);
          }
        })();
      }

      if (tg?.initDataUnsafe?.user) {
        const { id, photo_url, language_code } = tg.initDataUnsafe?.user;
        const lang = getLanguage(language_code);
        dispatch(setLanguage(lang));
        (async () => {
          try {
            await dispatch(
              getData({
                tgId: id,
                avatar: photo_url,
                siteLanguage: lang,
              })
            );
          } catch (error) {
            setError("gettingData");
            setErrorDeskShowing(true);
          } finally {
            setLoading(false);
          }
        })();
      } else {
        const lang = getLanguage();
        dispatch(setLanguage(lang));
      }
    }
    // checkIsBlocked();

    // get language from local storage

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="appContent">
      <div style={{ display: "none" }} id="script-wrapper"></div>
      {<AppLoader loading={loading || imageLoading} />}
      {errorDeskShowing ? (
        <ErrorDesk text={error && errorTexts[error]} />
      ) : (
        <>
          <Header />
          <AppRouter />
          <MobileNav />
        </>
      )}
    </main>
  );
};

export default AppContent;
