import React, { useState } from "react";
import "./BuildsWrapper.scss";
import BuildsTabBar from "../BuildsTabBar/BuildsTabBar";
import BuildsList from "../BuildsList/BuildsList";
import TutorialPopup from "components/TutorialPopup/TutorialPopup";
import { useTutorial } from "hooks/useTutorial";
import { lsProps } from "constants/lsProps";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const { startText, buildingsText, cityImproovmentText, specialBuildingsText } =
  TRANSLATIONS.buildings.tutorial;

export const tutorialSlides = (siteLanguage) => [
  {
    text: startText[siteLanguage],
  },
  {
    text: buildingsText[siteLanguage],
  },
  {
    text: cityImproovmentText[siteLanguage],
  },
  {
    text: specialBuildingsText[siteLanguage],
  },
];

const BuildsWrapper = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const [activeTab, setActiveTab] = useState("building");
  const { onClose, showTutorial } = useTutorial(
    lsProps.buildingsTutorialShowed
  );

  return (
    <>
      <section className="container">
        <div className="builds-wrapper space-control">
          <BuildsTabBar activeTab={activeTab} setActiveTab={setActiveTab} />
          <BuildsList activeTab={activeTab} />
        </div>
      </section>
      <TutorialPopup
        active={showTutorial}
        onClose={onClose}
        slides={tutorialSlides(siteLanguage)}
      />
    </>
  );
};

export default BuildsWrapper;
