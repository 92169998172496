import React from "react";
import "./SettingsPopup.scss";
import Svg from "components/layout/Svg/Svg";
import {
  resourcesPopupLeftFigureIcon,
  resourcesPopupRightFigureIcon,
} from "assets/svg";
import NewPortalProvider from "providers/NewPortalProvider";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { LANGUAGES } from "constants/languages";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "store/actions/ui";
import { TRANSLATIONS } from "assets/translations";
import { useNavigate } from "react-router-dom";
import { removeLSItems } from "utils/localStorage";
import { lsProps } from "constants/lsProps";
import { mainPagePath } from "router/path";
import { getData } from "store/actions/profile";

const { settingsText, languageText, tutorialText } = TRANSLATIONS.settingsPopup;

const SettingsPopup = ({ activePopup, onClose }) => {
  // const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const tgData = useSelector((state) => state.profile.tgData);

  const onResetTutorial = () => {
    removeLSItems(
      [
        lsProps.buildingsTutorialShowed,
        lsProps.homeTutorialShowed,
        lsProps.referalsTutorialShowed,
        lsProps.tasksTutorialShowed,
      ],
      () => {
        onClose();
        navigate(mainPagePath);
      }
    );
  };

  const onLanguageChange = async (lang) => {
    await dispatch(
      getData({
        ...tgData,
        siteLanguage: lang,
      })
    );
    dispatch(changeLanguage(lang));
  };

  return (
    <NewPortalProvider>
      <TransitionProvider
        inProp={activePopup}
        style={TransitionStyleTypes.opacity}
        className={`settings-popup`}
      >
        <div
          className="settings-popup__overlay"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <div className="settings-popup__body">
            <h2 className="settings-popup__title">
              {settingsText[siteLanguage]}
            </h2>
            <div className="settings-popup__wrap f-jcsb">
              <div className="settings-popup__figure">
                <Svg id={resourcesPopupLeftFigureIcon} />
              </div>

              <div className="settings-popup__figure">
                <Svg id={resourcesPopupRightFigureIcon} />
              </div>
            </div>
            <div className="settings-popup__main">
              <div className="settings-popup__language">
                <h4 className="settings-popup__item">
                  {languageText[siteLanguage]}
                </h4>
                <p className="settings-popup__languages-list">
                  {Object.values(LANGUAGES).map((item) => (
                    <button
                      onClick={() => onLanguageChange(item)}
                      key={item}
                      className={`settings-popup__language-list-item ${
                        siteLanguage === item ? "active" : ""
                      }`}
                    >
                      {item.toUpperCase()}
                    </button>
                  ))}
                </p>
              </div>

              <button
                onClick={onResetTutorial}
                className="settings-popup__item settings-popup__btnItem"
              >
                {tutorialText[siteLanguage]}
              </button>
            </div>
          </div>
        </div>
      </TransitionProvider>
    </NewPortalProvider>
  );
};

export default SettingsPopup;
