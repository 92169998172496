import React, { useState } from "react";
import "./BuildsList.scss";
import {
  buildsItemBgImg,
  buildsAccentImg,
  statsDarkImg,
  statsResImg,
  statsTokenImg,
  resIconImg,
  getResIconImg,
} from "assets/images";
import Svg from "components/layout/Svg/Svg";
import { buildsProgressCircleIcon, buildsUpgradeBgIcon } from "assets/svg";
import { statsImages } from "../../../constants/stats";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import { coinTypes } from "../../../constants/coinTypes";
import { adsgramBlocks } from "../../../constants/adsgram";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/formatNumber";
import { improoveBuilding } from "store/actions/buildings";
import { BUILD_IMAGES } from "constants/buildImages";
import { TRANSLATIONS } from "assets/translations";

const upgradeIncomes = [
  ["1.25x", ""],
  ["1.25x", ""],
  ["1.25x", "1.01x"],
  ["1.25x", "1.05x"],
  ["1.25x", "1.1x"],
  ["1.25x", "1.2x"],
];

const incomeTypes = {
  resources_per_hour: {
    statImg: resIconImg,
  },
  matter_per_hour: {
    statImg: statsDarkImg,
  },
  tokens_per_hour: {
    statImg: statsTokenImg,
  },
  bonus: {
    statImg: statsResImg,
  },
};

const {
  overallMiningSpeedText,
  hourText,
  buyText,
  levelText,
  upgradesText,
  lvlText,
  forText,
  freeText,
  improveText,
  upgradeText,
  viewAdText,
} = TRANSLATIONS.buildings;

const BuildListItem = ({
  image,
  id_building,
  income,
  price,
  completedUpgradeLevel,
  nextUpgradeLevel,
  buildingLevel,
  notBuyed,
  stats,
  openUpgradePopup,
  siteLanguage,
  ...props
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onImprooveBuild = async (id) => {
    setLoading(true);
    await dispatch(improoveBuilding(id));

    setLoading(false);
  };

  if (!nextUpgradeLevel) nextUpgradeLevel = 0;
  if (!completedUpgradeLevel) completedUpgradeLevel = 0;
  const lastLevel = nextUpgradeLevel || completedUpgradeLevel;
  return (
    <li className="builds-list__item" key={id_building}>
      <div className="builds-list__card">
        <div className="builds-list__image">
          <img
            className="cover-image"
            src={BUILD_IMAGES[props.name_eng]}
            alt={props.name_eng}
          />
        </div>
        <div className="builds-list__content">
          <div className="builds-list__titleBlock">
            <h2 className="builds-list__title">
              {props[`name_${siteLanguage}`] || props.name_rus}
            </h2>
          </div>
          <p className={"builds-list__progress-title"}>
            {upgradesText[siteLanguage]}
          </p>
          <div className="builds-list__progress">
            {Array.from({
              length: 6,
            }).map((_, upgradeIndex) => {
              const lastIndex = +nextUpgradeLevel || +completedUpgradeLevel;
              return (
                <button
                  disabled={
                    upgradeIndex > lastIndex - 1 ||
                    loading ||
                    completedUpgradeLevel >= upgradeIndex + 1
                  }
                  className={`builds-list__progress-item ${
                    upgradeIndex >= lastIndex ? "hidden" : ""
                  }`}
                  key={upgradeIndex}
                  onClick={() => {
                    openUpgradePopup(upgradeIndex, id_building);
                  }}
                >
                  <div className="builds-list__progress-card">
                    <p className="builds-list__progress-value">
                      {upgradeIndex + 1}
                    </p>
                  </div>
                  <div className="builds-list__progress-bg">
                    <img
                      src={
                        completedUpgradeLevel === upgradeIndex + 1
                          ? buildsAccentImg
                          : buildsItemBgImg
                      }
                      alt="upgrade"
                    />
                  </div>
                </button>
              );
            })}

            {lastLevel > 3 && (
              <div className="builds-list__progress-circle builds-list__progress-circle_first">
                <Svg id={buildsProgressCircleIcon} />
              </div>
            )}
            {lastLevel > 4 && (
              <div className="builds-list__progress-circle builds-list__progress-circle_second">
                <Svg id={buildsProgressCircleIcon} />
              </div>
            )}
          </div>
        </div>
        <div className="builds-list__token">
          <div className="builds-list__earn f-center-center">
            {formatNumber(income.value)}
            <img src={incomeTypes[income.type].statImg} alt="" />/
            {hourText[siteLanguage]}
          </div>
          <div className="builds-list__token-main">
            {!!buildingLevel && (
              <div className="builds-list__level f-center-center">
                {buildingLevel} {lvlText[siteLanguage]}
              </div>
            )}
            <button
              disabled={price.value > stats[price.type] || loading}
              onClick={() => {
                onImprooveBuild(id_building);
              }}
              type="button"
              className="builds-list__upgrade f-center-center"
            >
              <div className="builds-list__upgrade-content">
                {!notBuyed && (
                  <p className="builds-list__upgrade-lvl">
                    <span>+1</span> {levelText[siteLanguage]}
                  </p>
                )}
                <p className="builds-list__upgrade-title">
                  {notBuyed ? buyText[siteLanguage] : improveText[siteLanguage]}
                </p>
                <p className="builds-list__upgrade-price f-center-center">
                  {forText[siteLanguage]} <span>{formatNumber(price.value)}</span>{" "}
                  <img src={statsImages[price.type]} alt="" />
                </p>
              </div>
              <div className="builds-list__upgrade-bg">
                <Svg id={buildsUpgradeBgIcon} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

const BuildsList = ({ activeTab }) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const builds = useSelector((state) => state.buildings.buildings);
  const summary_income = useSelector((state) => state.profile.summary_income);
  const [activeUpgradePopupIndex, setActiveUpgradePopupIndex] = useState(0);
  const [activeUpgradePopupId, setActiveUpgradePopupId] = useState(null);
  const [upgradePopupOpened, setUpgradePopupOpened] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const stats = useSelector((state) => state.profile.stats);

  const openUpgradePopup = (upgradeIndex, id) => {
    setActiveUpgradePopupIndex(upgradeIndex + 1);
    setActiveUpgradePopupId(id);
    setUpgradePopupOpened(true);
  };

  const curBuild = builds.find(
    (build) => build.id_building === activeUpgradePopupId
  );

  const onUpgrade = async (useAd) => {
    setUpgradeLoading(true);
    if (activeUpgradePopupId) {
      await dispatch(
        improoveBuilding(
          activeUpgradePopupId,
          0,
          activeUpgradePopupIndex,
          useAd
        )
      );
    }
    setUpgradeLoading(false);
    setUpgradePopupOpened(false);
  };
  return (
    <>
      <div className="builds-list__summary_income">
        <span>{overallMiningSpeedText[siteLanguage]}</span>
        <strong>{formatNumber(summary_income)}</strong>
        <img src={getResIconImg} alt="resources" />
        <strong>/{hourText[siteLanguage]}</strong>
      </div>
      <ul className="builds-list">
        {builds
          .filter((item) => item.type === activeTab)
          .map((build) => (
            <BuildListItem
              {...build}
              stats={stats}
              openUpgradePopup={openUpgradePopup}
              closeUpgradePopup={() => {
                setUpgradePopupOpened(false);
              }}
              key={build.id_building}
              siteLanguage={siteLanguage}
            />
          ))}

        {/* upgrade popup */}
        <ResourcesPopup
          activePopup={!!upgradePopupOpened}
          onClosePopup={() => setUpgradePopupOpened(false)}
          loading={upgradeLoading}
          title={`${upgradeText[siteLanguage]} ${activeUpgradePopupIndex}`}
          primaryBtnStats={[
            {
              text: formatNumber(+curBuild?.upgradeBuildingCost),
              type: coinTypes.resources,
            },
          ]}
          incomes={
            upgradeIncomes[activeUpgradePopupIndex - 1]
              ? upgradeIncomes[activeUpgradePopupIndex - 1]
                  .filter((item) => item)
                  .map((item) => ({ text: item, type: coinTypes.resources }))
              : []
          }
          adIncomes={[{ text: freeText[siteLanguage], type: coinTypes.resources }]}
          blockId={adsgramBlocks.buildingUpgrade}
          primaryBtnTxt={improveText[siteLanguage]}
          adbtnClass={"builds-list__adBtn"}
          primaryBtnClass={"builds-list__adBtn"}
          adBtnTxt={viewAdText[siteLanguage]}
          onClickPrimaryBtn={
            curBuild?.upgradeBuildingCost &&
            stats.resources >= curBuild?.upgradeBuildingCost
              ? onUpgrade
              : undefined
          }
          onClickAdBtn={() => onUpgrade(true)}
        />
      </ul>
    </>
  );
};

export default BuildsList;
