import CryptoJS from 'crypto-js';

export const generateSignature = (apikey,tgID) => {
  const timestamp = Math.floor(Date.now() / 1000); 

  const signaturePayload = `${tgID}${timestamp}`;
  const hash = CryptoJS.HmacSHA256(signaturePayload, apikey);
  const signatureHex = hash.toString(CryptoJS.enc.Hex);

  return {signatureHex,timestamp};
}