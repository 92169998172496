import { initBgImg } from "assets/images";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import React from "react";

import "./AppLoader.scss";
import { useLocation } from "react-router-dom";

const AppLoader = ({ loading }) => {
  const location = useLocation();
  return (
    <TransitionProvider
      style={TransitionStyleTypes.opacity}
      inProp={loading && !location.search.includes("popup=open")}
      className="app-loader"
    >
      <img src={initBgImg} alt="loader" className="app-loader__img container" />
    </TransitionProvider>
  );
};

export default AppLoader;
