import React from "react";

import "./FriendsWrapper.scss";
import FriendsMain from "../FriendsMain/FriendsMain";
import FriendsRewards from "../FriendsRewards/FriendsRewards";
import TutorialPopup from "components/TutorialPopup/TutorialPopup";
import { useTutorial } from "hooks/useTutorial";
import { lsProps } from "constants/lsProps";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const { startText, resetText, inviteText } = TRANSLATIONS.freinds.tutorial;

const tutorialSlides = (siteLanguage) => [
  {
    text: startText[siteLanguage],
  },
  {
    text: resetText[siteLanguage],
  },
  {
    text: inviteText[siteLanguage],
  },
];

const FriendsWrapper = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const { onClose, showTutorial } = useTutorial(lsProps.referalsTutorialShowed);
  return (
    <>
      <div className="container">
        <div className="friends-wrapper space-control">
          <FriendsMain />
          <FriendsRewards />
        </div>
      </div>
      <TutorialPopup
        active={showTutorial}
        onClose={onClose}
        slides={tutorialSlides(siteLanguage)}
      />
    </>
  );
};

export default FriendsWrapper;
