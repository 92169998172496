import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
};

const tasksSlice = createSlice({
  name: "tasks",
  
  initialState,
  reducers: {
  
  },
});

// export const { getDailyLogin, increaseDailyTimer } = tasksSlice.actions;

export default tasksSlice.reducer;
