import React from "react";
import Svg from "../layout/Svg/Svg";
import { resourcesPopupCollectBtnBgIcon } from "../../assets/svg";

import "./ErrorDesk.scss";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { useSelector } from "react-redux";
import { TRANSLATIONS } from "assets/translations";

const {
  refreshText
} = TRANSLATIONS.errorDesk

const ErrorDesk = ({text}) => {
    const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <TransitionProvider
      inProp={true}
      style={TransitionStyleTypes.opacity}
      className="error-desk"
    >
      <h3 className="error-desk__text">
        {text[siteLanguage]}</h3>
      <button
        type="button"
        className="error-desk__btn"
        onClick={() => window.location.reload()}
      >
        {refreshText[siteLanguage]}
        <div className="error-desk__btn-bg">
          <Svg id={resourcesPopupCollectBtnBgIcon} />
        </div>
      </button>{" "}
    </TransitionProvider>
  );
};

export default ErrorDesk;
