import { useEffect, useRef, useState } from "react";

export const useTooltip = () => {
  const [show, setShow] = useState(false);
  const timeOutRef = useRef(null);

  useEffect(() => {
    if (show) {
      clearTimeout(timeOutRef.current);

      timeOutRef.current = setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [show]);

  const openTooltip = () => {
    setShow(true);
  };


  return {openTooltip,show}
};
