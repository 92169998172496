import { createSlice } from "@reduxjs/toolkit";
import {LANGUAGES} from "../../constants/languages"


const initialState = {
  siteLanguage: LANGUAGES.rus,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLanguage(state, { payload }) {
      state.siteLanguage = payload;
    },
  },
});

export const { setLanguage } = uiSlice.actions;

export default uiSlice.reducer;
