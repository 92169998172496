import {
  banksImage,
  colliderImage,
  dumpsImage,
  industrialFarmsImage,
  machineBuildingPlantsImage,
  metallurgicalPlantsImage,
  minesImage,
  powerPlantsImage,
  repairPlantsImage,
  researchInstitutesImage,
  residentialComplexesImage,
} from "../assets/images";

export const BUILD_IMAGES = {
  "Dumps": dumpsImage,
  "Mines": minesImage,
  "Industrial Farms": industrialFarmsImage,
  "Power Plants": powerPlantsImage,
  "Metallurgical Plants": metallurgicalPlantsImage,
  "Repair Plants": repairPlantsImage,
  "Machine-Building Plants": machineBuildingPlantsImage,
  "Residential Complexes": residentialComplexesImage,
  "Banks": banksImage,
  "Research Institutes": researchInstitutesImage,
  "Collider": colliderImage,
};
