import { combineReducers } from 'redux';
import profileReducer from '../slices/profileSlice';
import buildingsReducer from '../slices/buildingsSlice';
import uiReducer from '../slices/uiSlice';
import tasksReducer from '../slices/tasksSlice';

const rootReducer = combineReducers({
  profile: profileReducer,
  buildings: buildingsReducer,
  ui: uiReducer,
  tasks: tasksReducer,
});

export default rootReducer;
