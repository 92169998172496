import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buildings: [],
};
// buildings: [
//   {
//     name: "Свалки",
//     image: "свалки.png",
//     type: "building",
//     income: {
//       type: "resources_per_hour",
//       value: 1,
//     },
//     price: {
//       type: "resources",
//       value: 10,
//     },
//     completedUpgradeLevel: 0,
//     buildingLevel: 1,
//   },
// ],

const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    getbuildings(state, { payload }) {
      state.buildings = payload;
    },
  },
});

export const { getbuildings } = buildingsSlice.actions;

export default buildingsSlice.reducer;
