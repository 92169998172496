export const useAdController = (blockId,scsClb) => {
  const AdController = window.Adsgram.init({ blockId });

  const onShowAd = () => {
    AdController.show()
      .then((result) => {
        if(result.done && scsClb){
           scsClb()
        }
      })
      .catch((result) => {
        console.error("err", result);
      });
  };

  return onShowAd;
};
