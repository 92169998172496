import React, { useState } from "react";
import "./TasksWrapper.scss";
import TasksTabBar from "../TasksTabBar/TasksTabBar";
import TasksList from "../TasksList/TasksList";
import TutorialPopup from "components/TutorialPopup/TutorialPopup";
import { useTutorial } from "hooks/useTutorial";
import { lsProps } from "constants/lsProps";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const tutorialText = TRANSLATIONS.tasks.tutorialText;
const tutorialSlides = (siteLanguage) => [
  {
    text: tutorialText[siteLanguage],
  },
];

const TasksWrapper = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const [activeTab, setActiveTab] = useState(0);
  const { showTutorial, onClose } = useTutorial(lsProps.tasksTutorialShowed);
  return (
    <>
      <section className="container">
        <div className="tasks-inner space-control">
          <TasksTabBar activeTab={activeTab} setActiveTab={setActiveTab} />
          <TasksList activeTab={activeTab} />
        </div>
      </section>
      <TutorialPopup
        active={showTutorial}
        onClose={onClose}
        slides={tutorialSlides(siteLanguage)}
      />
    </>
  );
};

export default TasksWrapper;
