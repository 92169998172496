import {
  updateUserDetails,
  getUser,
  resourcesHarvest,
  collectDaily,
  collectRefIcome,
  updateSubscribeTask,
  updateSubscribePartnersTask,
  updateStats,
} from "store/slices/profileSlice";
import { fetchRequest } from "../tools/fetchTools";
import { getbuildings } from "store/slices/buildingsSlice";

const getDataUrl = "account/";
const renameUserUrl = "account/rename/";
const harvestUrl = "harvest/";
const getBonusUrl = "ads/bonus/";
const collectDailyUrl = "tasks/dailylogin/";
const collectRefIncomeUrl = "referalbonus/";
const subscribeTaskUrl = "tasks/subscribe/";
const subscribePartnersTaskUrl = "tasks/subscribe_partners/";
const rewardTraffyTaskUrl = "tasks/traffy/";

export const getData =
  ({ tgId, avatar, siteLanguage }) =>
  async (dispatch) => {
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 8000);
      });
      const dataPromise = fetchRequest(getDataUrl, tgId, siteLanguage);
      const data = await Promise.race([dataPromise, timeoutPromise]);

      if (data) {
        const {
          buildings,
          next_building,
          time_after_harvest,
          quantity_harvest,
          max_time_harvest,
          time_after_intermediate_harvest,
          timer_speed_bonus,
          date_permanent_bonus,
          value_resources_bonus,
          step_permanent_bonus,
          value_permanent_bonus,
          ref_link,
          ref_checkpoint,
          summary_income,
          ref_resources,
          ref_matter,
          count_second_level_referrals,
          resources,
          matter,
          tokens,
          tasks,
        } = data;

        dispatch(
          getUser({
            tgData: { tgId, avatar },
            time_after_harvest,
            quantity_harvest,
            time_after_intermediate_harvest,
            max_time_harvest,
            timer_speed_bonus,
            summary_income,
            step_permanent_bonus,
            date_permanent_bonus,
            ref_link,
            ref_checkpoint,
            ref_resources,
            ref_matter,
            count_second_level_referrals,
            value_permanent_bonus,
            value_resources_bonus,
            dailylogin: tasks.dailylogin,
            subscribe: tasks.subscribe,
            subscribe_partners: tasks.subscribe_partners,
            stats: {
              resources,
              matter,
              tokens,
            },
          })
        );
        dispatch(
          getbuildings([
            ...buildings,
            {
              ...next_building,
              notBuyed: true,
            },
          ])
        );
      }
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line no-undef
  };

export const renameUser = () => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const data = await fetchRequest(renameUserUrl, tgId, siteLanguage, "PUT", {
      new_name: "newName",
    });
    if (data) {
    }
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const harvest = (isWithAd) => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    let data = null;
    if (!isWithAd) {
      data = await fetchRequest(harvestUrl, tgId, siteLanguage, "PUT");
    } else {
      data = await fetchBonus(tgId, siteLanguage, getBonusTypes.harvest, true);
    }

    if (data && data.account) {
      const { time_after_harvest, resources, quantity_harvest } = data.account;
      dispatch(
        resourcesHarvest({ time_after_harvest, resources, quantity_harvest })
      );
    }
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const getBonusTypes = {
  permanent: "permanent",
  speed: "speed",
  resources: "resources",
  harvest: "harvest",
  upgrade: "upgrade",
};

export const fetchBonus = async (
  tgId,
  siteLanguage,
  type,
  is_ad_bonus,
  building_id = 0
) => {
  const data = await fetchRequest(getBonusUrl, tgId, siteLanguage, "POST", {
    bonus_type: type,
    cost: 0.0,
    is_ad_bonus,
    building_id,
  });

  return data;
};

export const getSubscribeTaskIncome = async (
  tgId,
  type,
  is_ad_bonus,
  building_id = 0
) => {
  const data = await fetchRequest(getBonusUrl, tgId, "POST", {
    bonus_type: type,
    cost: 0.0,
    is_ad_bonus,
    building_id,
  });

  return data;
};

export const getBonus = (type, is_ad_bonus) => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const data = await fetchBonus(tgId, siteLanguage, type, is_ad_bonus);

    if (!data.account) return;

    const {
      time_after_harvest,
      quantity_harvest,
      time_after_intermediate_harvest,
      timer_speed_bonus,
      summary_income,
      max_time_harvest,
      step_permanent_bonus,
      date_permanent_bonus,
      value_permanent_bonus,
      value_resources_bonus,
      resources,
      matter,
      tokens,
    } = data.account;

    dispatch(
      updateUserDetails({
        stats: {
          resources,
          matter,
          tokens,
        },
        time_after_harvest,
        quantity_harvest,
        time_after_intermediate_harvest,
        timer_speed_bonus,
        step_permanent_bonus,
        date_permanent_bonus,
        value_permanent_bonus,
        value_resources_bonus,
        summary_income,
        max_time_harvest,
      })
    );
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const fetchDailyCollect = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  const data = await fetchRequest(collectDailyUrl, tgId, siteLanguage, "POST");
  dispatch(
    collectDaily({
      stats: {
        resources: data.account.resources,
        matter: data.account.matter,
        tokens: data.account.tokens,
      },
      dailylogin: data.account.tasks.dailylogin,
    })
  );
};

export const fetchColectRefIncome = (bonus) => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      collectRefIncomeUrl + (bonus ? `?bonus=True` : ""),
      tgId,
      siteLanguage,
      "GET"
    );
    dispatch(
      collectRefIcome({
        stats: {
          resources: data.account.resources,
          matter: data.account.matter,
          tokens: data.account.tokens,
        },
        ref_matter: data.account.ref_matter,
        ref_resources: data.account.ref_resources,
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const subscribeTask = (url) => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      subscribeTaskUrl + `?channel_url=${url}`,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens } = data.account;
    dispatch(
      updateSubscribeTask({
        key: url,
        data: data.account.tasks.subscribe[url],
        stats: { resources, matter, tokens },
      })
    );
  } catch (error) {
    return error;
  }
};

export const rewardTraffyTask = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      rewardTraffyTaskUrl,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens } = data.account;
    dispatch(updateStats({ resources, matter, tokens }));
  } catch (error) {
    return error;
  }
};

export const subscribePartnersTask =
  (url, key) => async (dispatch, getState) => {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const params = new URLSearchParams({
      channel_url: url,
    });
    try {
      const data = await fetchRequest(
        `${subscribePartnersTaskUrl}?${params.toString()}`,
        tgId,
        siteLanguage,
        "POST"
      );

      const { resources, matter, tokens } = data.account;
      dispatch(
        updateSubscribePartnersTask({
          key,
          data: data.account.tasks.subscribe_partners[key],
          stats: { resources, matter, tokens },
        })
      );
    } catch (error) {
      return error;
    }
  };
