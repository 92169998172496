import { tutorialGirlImg } from "assets/images";
import Popup from "components/layout/Popup/Popup";
import React, {  useLayoutEffect, useRef, useState } from "react";
import Svg from "components/layout/Svg/Svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { friendsCopyBtnBgIcon, friendsShareBtnBgIcon } from "../../assets/svg";

import "./TutorialPopup.scss";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const { closeText, nextText } = TRANSLATIONS.tutorialPopup;

const TutorialPopup = ({ active, onClose, slides }) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const nextButtonRef = useRef(null);
  const mainRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (mainRef?.current) {
        setImgHeight(
          window.innerHeight -
            mainRef?.current.getBoundingClientRect().height -
            30
        );
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainRef.current]);

  return (
    <Popup activePopup={active} onClose={onClose}>
      <div className="tutorialPopup container">
        <img
          src={tutorialGirlImg}
          alt="firl"
          className="tutorialPopup__img"
          style={{
            maxHeight: mainRef?.current ? `${imgHeight}px` : "0px",
          }}
        />
        {/* <div className="tutorialPopup__img-wrapper">
        </div> */}
        <div className="tutorialPopup__main" ref={mainRef}>
          <Swiper
            className="tutorialPopup__slider"
            slidesPerView={1}
            spaceBetween={40}
            onSlideChange={handleSlideChange}
            modules={[Navigation]}
            onBeforeInit={(swiper) => {
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.nextEl = nextButtonRef.current;
            }}
            navigation={{
              nextEl: nextButtonRef.current,
              // prevEl: styles.projectsHeader__arrowBtn_next,
            }}
          >
            {slides.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  {slide.img && (
                    <img
                      src={slide.img}
                      alt=""
                      className={`tutorialPopup__main-img ${
                        index === activeIndex ? "active" : ""
                      }`}
                    />
                  )}
                  <p
                    className={`tutorialPopup__main-text ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    {slide.text.split(" ").map((word, wordIndex) => (
                      <span key={wordIndex}>
                        {word.split("").map((letter, letterIndex) => {
                          const globalIndex =
                            slide.text
                              .split(" ")
                              .slice(0, wordIndex) // Take all previous words
                              .join("").length + // Count their characters
                            wordIndex + // Account for spaces
                            letterIndex; // Add the current letter index
                          return (
                            <span
                              key={letterIndex}
                              style={{
                                animationDelay: `${globalIndex * 0.03}s`,
                              }}
                            >
                              {letter === " " ? "\u00A0" : letter}
                            </span>
                          );
                        })}
                        {"\u00A0"}
                      </span>
                    ))}
                  </p>
                </SwiperSlide>
              );
            })}

            <div className="tutorialPopup__btns-wrapper">
              <button
                onClick={onClose}
                type="button"
                className="tutorialPopup__nav-btn"
              >
                <div className="tutorialPopup__nav-offer f-center-center">
                  {closeText[siteLanguage]}
                </div>
                <div className="tutorialPopup__nav-btn-bg">
                  <Svg id={friendsCopyBtnBgIcon} />
                </div>
              </button>
              <button
                ref={nextButtonRef}
                type="button"
                className={`tutorialPopup__nav-btn tutorialPopup__next-btn ${
                  activeIndex === slides.length - 1 ? "hidden" : ""
                }`}
              >
                <div className="tutorialPopup__nav-offer f-center-center">
                  {nextText[siteLanguage]}
                </div>
                <div className="tutorialPopup__nav-btn-bg">
                  <Svg id={friendsShareBtnBgIcon} />
                </div>
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </Popup>
  );
};

export default TutorialPopup;
