import * as allImages from "../assets/images";
import { useEffect, useState } from "react";

const preloadImages = (imageArray) => {
  return Promise.all(
    imageArray.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

export const useImageLoader = () => {
  const [loading, setLoading] = useState(true);

  const imagesArr = Object.values(allImages)
  useEffect(() => {
    setLoading(true);
    preloadImages(imagesArr)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to load images", err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
};
