/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateHarvest } from "store/slices/profileSlice";

export const useHarvestPerSec = () => {
  const dispatch = useDispatch();
  const timeAfterHarvest = useSelector(
    (state) => state.profile.time_after_harvest
  );
  const maxTimeHarvest = useSelector((state) => state.profile.max_time_harvest);
  const summaryIncome = useSelector((state) => state.profile.summary_income);
  const [shouldTimerWork, setShouldTimerWork] = useState(false);

  const timeAfterHarvestRef = useRef(timeAfterHarvest);
  const maxTimeHarvestRef = useRef(maxTimeHarvest);
  const summaryIncomeRef = useRef(summaryIncome);
  const intervalRef = useRef(null);

  useEffect(() => {
    timeAfterHarvestRef.current = timeAfterHarvest;

    if (!shouldTimerWork && timeAfterHarvest) {
      setShouldTimerWork(true);
    } else if (shouldTimerWork && !timeAfterHarvest) {
      setShouldTimerWork(false);
    }
  }, [timeAfterHarvest]);

  useEffect(() => {
    summaryIncomeRef.current = summaryIncome;
  }, [summaryIncome]);

  useEffect(() => {
    maxTimeHarvestRef.current = maxTimeHarvest;
  }, [maxTimeHarvest]);

  useEffect(() => {
    if (!shouldTimerWork && timeAfterHarvest < maxTimeHarvest) {
      setShouldTimerWork(true);
    } else if (shouldTimerWork && !maxTimeHarvest) {
      setShouldTimerWork(false);
    }
  }, [timeAfterHarvest, maxTimeHarvest]);

  useEffect(() => {
    const harvest = () => {
      if (
        timeAfterHarvestRef.current < maxTimeHarvest &&
        summaryIncomeRef.current
      ) {
        dispatch(updateHarvest());
      }
    };

    if (shouldTimerWork) {
      intervalRef.current = setInterval(() => {
        harvest();
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTimerWork]);
};
