import { useEffect, useState } from "react";
import { getLSItem, setLSItem } from "utils/localStorage";

export const useTutorial = (lsProp, dep) => {
  const [showTutorial, setShowTutorial] = useState(false);
  useEffect(
    () => {
      getLSItem(lsProp, (_, lsData) => {
        if (!lsData) setShowTutorial(true);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dep ? [dep] : []
  );

  const onClose = () => {
    setShowTutorial(false);
    setLSItem(lsProp, true);
  };

  return { onClose, showTutorial };
};
