import React from "react";

import { useDispatch, useSelector } from "react-redux";
import "./FriendsMain.scss";
import { useState } from "react";
import { resIconImg } from "assets/images";
import { statsDarkImg } from "assets/images";
import Svg from "components/layout/Svg/Svg";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import { coinTypes } from "../../../constants/coinTypes";
import { adsgramBlocks } from "../../../constants/adsgram";
import {
  friendsCopyBtnBgIcon,
  friendsCopyIcon,
  friendsEarnBgIcon,
  friendsEarnBtnBgIcon,
  friendsShareBtnBgIcon,
  friendsShareIcon,
  friendsStatusBgIcon,
  friendsStatusFigureIcon,
} from "assets/svg";
import { TRANSLATIONS } from "assets/translations";
import { formatNumber } from "utils/formatNumber";
import { fetchColectRefIncome } from "store/actions/profile";
import { useTooltip } from "hooks/usetooltip";
import Tooltip from "components/layout/Tooltip/Tooltip";

const {
  friendsText,
  collectText,
  earnedForAllTimeText,
  unconfirmedText,
  confirmedText,
  advancedText,
  copyLinkText,
  copiedText,
  inviteFriendText,
  secondLevelRefText,
  noRewardText,
} = TRANSLATIONS.freinds;

const SHARE_TEXT =
  "Develop your city, team up with other players to fight for territories, level up your character and become the best! Enjoy the game and get tokens.";

const CollectBtn = ({ sitelanguage, ref_resources, ref_matter }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activePopup, setActivePopup] = useState(false);
  const { openTooltip, show } = useTooltip();

  const onCollect = async (bonus) => {
    setLoading(true);
    dispatch(fetchColectRefIncome(bonus));
    setLoading(false);
    setActivePopup(false);
  };
  return (
    <>
      <ResourcesPopup
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        loading={loading}
        incomes={[
          { text: ref_resources, type: coinTypes.resources },
          { text: ref_matter, type: coinTypes.matter },
        ]}
        adIncomes={[
          { text: "x1,5", type: coinTypes.resources },
          { text: "x1,1", type: coinTypes.matter },
        ]}
        blockId={adsgramBlocks.referralHarvest}
        onClickPrimaryBtn={onCollect}
        onClickAdBtn={() => onCollect(true)}
      />

      <button
        onClick={() =>
          !ref_resources && !ref_matter ? openTooltip() : setActivePopup(true)
        }
        type="button"
        className="friends-main__earn-btn"
      >
        {collectText[sitelanguage]}
        <div className="friends-main__earn-btn-bg">
          <Svg id={friendsEarnBtnBgIcon} />
        </div>
      </button>
      <Tooltip show={show} text={noRewardText[sitelanguage]}/>
    </>
  );
};

const FriendsMain = () => {
  const [copied, setCopied] = useState(false);
  const ref_link = useSelector((state) => state.profile.ref_link);
  const ref_resources = useSelector((state) => state.profile.ref_resources);
  const ref_matter = useSelector((state) => state.profile.ref_matter);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const count_second_level_referrals = useSelector(
    (state) => state.profile.count_second_level_referrals
  );

  const { count_reset, count_harvest, count_registration } = useSelector(
    (state) => state.profile.ref_checkpoint
  );

  const onInvite = () => {
    window.open(
      `https://t.me/share/url?url=${ref_link}&text=${SHARE_TEXT}`,
      "_blank"
    );
  };

  const copyToClipboard = () => {
    const addMembersUrl = ref_link;

    navigator.clipboard
      .writeText(addMembersUrl)
      .then(() => {
        setCopied(true);

        setTimeout(function () {
          setCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error("Err copy: ", err);
      });
  };

  return (
    <>
      <h2 className="friends-main__title">{friendsText[siteLanguage]}</h2>
      <div className="friends-main__earn f-center-jcsb">
        <div className="friends-main__earn-all">
          <p className="friends-main__earn-all-title">
            {earnedForAllTimeText[siteLanguage]}
          </p>
          <ul className="friends-main__earn-res f-center">
            <li className="friends-main__earn-res-item f-center">
              <div className="friends-main__earn-res-icon">
                <img className="cover-image" src={resIconImg} alt="" />
              </div>
              <p className="friends-main__earn-res-value">
                {formatNumber(ref_resources)}
              </p>
            </li>
            <li className="friends-main__earn-res-item f-center">
              <div className="friends-main__earn-res-icon">
                <img className="cover-image" src={statsDarkImg} alt="" />
              </div>
              <p className="friends-main__earn-res-value">
                {formatNumber(ref_matter)}
              </p>
            </li>
          </ul>
        </div>
        <CollectBtn
          sitelanguage={siteLanguage}
          ref_resources={ref_resources}
          ref_matter={ref_matter}
        />
        <div className="friends-main__earn-bg">
          <Svg id={friendsEarnBgIcon} />
        </div>
      </div>
      <ul className="friends-main__status">
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">
            {unconfirmedText[siteLanguage]}
          </h3>
          <p className="friends-main__status-value">{count_registration}</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
        </li>
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">
            {confirmedText[siteLanguage]}
          </h3>
          <p className="friends-main__status-value">{count_harvest}</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
          <div className="friends-main__status-figure">
            <Svg id={friendsStatusFigureIcon} />
          </div>
        </li>
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">
            {advancedText[siteLanguage]}
          </h3>
          <p className="friends-main__status-value">{count_reset}</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
          <div className="friends-main__status-figure">
            <Svg id={friendsStatusFigureIcon} />
          </div>
        </li>
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">
            {secondLevelRefText[siteLanguage]}
          </h3>
          <p className="friends-main__status-value">
            {count_second_level_referrals}
          </p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
          <div className="friends-main__status-figure">
            <Svg id={friendsStatusFigureIcon} />
          </div>
        </li>
      </ul>
      <div className="friends-main__nav f-center-jcsb">
        <button
          type="button"
          className="friends-main__nav-btn"
          onClick={copyToClipboard}
        >
          <div className="friends-main__nav-offer f-center-center">
            <Svg id={friendsCopyIcon} />
            {copied ? copiedText[siteLanguage] : copyLinkText[siteLanguage]}
          </div>
          <div className="friends-main__nav-btn-bg">
            <Svg id={friendsCopyBtnBgIcon} />
          </div>
        </button>
        <button
          onClick={onInvite}
          type="button"
          className="friends-main__nav-btn"
        >
          <div className="friends-main__nav-offer f-center-center">
            <Svg id={friendsShareIcon} />
            {inviteFriendText[siteLanguage]}
          </div>
          <div className="friends-main__nav-btn-bg">
            <Svg id={friendsShareBtnBgIcon} />
          </div>
        </button>
      </div>
    </>
  );
};

export default FriendsMain;
