import React from "react";
import NewPortalProvider from "../../../providers/NewPortalProvider";
import TransitionProvider from "providers/TransitionProvider";
import { TransitionStyleTypes } from "../../../providers/TransitionProvider";

import "./Popup.scss"

const Popup = ({ activePopup, onClose, children }) => {
  return (
    <NewPortalProvider>
      <TransitionProvider
        inProp={activePopup}
        style={TransitionStyleTypes.opacity}
        className={`popup`}
      >
        <div
          className="popup__overlay"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          {children}
        </div>
      </TransitionProvider>
    </NewPortalProvider>
  );
};

export default Popup;
